import React, {useRef, useState} from "react";
import emailjs from 'emailjs-com';
import clock from "../assets/clock.png";
import message from "../assets/message.png";
import phone from "../assets/phone.png";

const About = () => {

  const form = useRef();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send the form data as an object
    emailjs.send(
      'service_l2gvgsj',     // Your EmailJS Service ID
      'template_l0uy66s',     // Your EmailJS Template ID
      {
        name: formData.name,
        email: formData.email,
        phone: formData.phone
      },
      'user_YqdXBLYfCt9S3vDdCQLr7' // Your EmailJS User ID
    ).then((result) => {
      console.log(result.text);
      alert("Thank You! Your message has been sent.");
    }).catch((error) => {
      console.log(error.text);
      alert("There was an error sending your message. Please try again.");
    });
  };

  return (
    <>
      <section className="aboutus" id="us">
        <div className="aboutus__wrapper">
          <div className="aboutus__content">
            <h2 className="aboutus__content-heading">About Us</h2>
            <div className="aboutus__content-text">
              <p>
                Securewealthai was launched a few years back with a unique vision to
                provide unparalleled advanced trading conditions, advanced
                education, and state-of-the-art trading tools in the Forex
                industry. Due to the continuous focus on localizing our products
                and services to suit each market, together with our successful
                collaboration with talented and experienced teams from all over
                the world. Securewealthai was quickly established as the global
                trademark it is today.
              </p>
              <p>
                Our number one mission is centered on time. By valuing time and
                understanding the importance of efficiency in the fluctuating,
                lightning-fast world of financial markets, every decision we
                make is designed to ensure that our clients and business
                partners, as well as our team of professionals, invest their
                time wisely. This road is paved by an ironclad focus on
                educating our customers. Knowledge is power, and empowering
                traders to get the best value for their investments is our
                passion.
              </p>
              <p>
                At Securewealthai we are committed to making sure all our clients and
                investors stay connected to the various opportunities that arise
                in the market on a daily basis and ease in connecting with one
                another. With Securewealthai has your financial stockbroker you are
                sure to get all the professional backing and support you require
                to soar in Forex trading.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="contact" id="contact">
        <div className="contact__heading">
          <h2>Contact Us</h2>
        </div>
        <div className="contact__content">
          <div className="contact__content-details">
            <div className="date">
              <img src={clock} alt="date" />
              <h4>
                Address: HSBC Canary Wharf Canada Place <br/>Unit 8, Canada Square, Retail E14 5AH
              </h4>
            </div>
            <div className="email">
              <img src={message} alt="email" />
              <h4><a href="mailto:support@securewealthai.pro ">support@securewealthai.pro </a></h4>
            </div>
            <div className="phone">
              <img src={phone} alt="phone" />
              <h4>Support phone:+442038377493</h4>
            </div>
          </div>
          <div className="contact__content-form">
            <h2 className="contact__content-form-heading">GET IN TOUCH</h2>
            <form ref={form} onSubmit={handleSubmit}>
              <div className="input__box">
                <i className="far fa-user"></i>
                <input type="text" name="name" placeholder="Name"
        onChange={handleChange}
        required />
              </div>
              <div className="input__box">
                <i className="far fa-envelope"></i>
                <input type="text" name="email" placeholder="Email"
        onChange={handleChange}
        required />
              </div>
              <div className="input__box">
                <i className="fas fa-phone-alt"></i>
                <input type="text" name="phone" placeholder="Contact No."
        onChange={handleChange}
        required />
              </div>
              <button className="form__btn" onClick={handleSubmit}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
