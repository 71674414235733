import React from "react";
import laptop from "../assets/tradingImg.png";
const Trading = () => {
  return (
    <>
      <div className="trading__platform-heading">
        <h2>Trading Platform</h2>
      </div>
      <section className="trading__platform">
        <div className="trading__platform__wrapper">
          <div className="web__trader">
            <div className="web__trader-1">
              <img src={laptop} alt="laptop" />
              <div className="web__trader-1-content">
                <h2>Web Trader</h2>
                <p>
                  Securewealthai highest purpose is to turn its clients into
                  successful traders and increase their success rates as much as
                  possible. For that purpose we give you the Securewealthai Online
                  trader, which enables you to be 100% involved in the trading
                  process and adjust according to the market conditions, all the
                  while letting you plan your trading activities to your
                  preference.
                </p>
                <p>
                  Utilize the full scope of the latest technological
                  developments in the field by trading from any internet
                  connectable device. Investing in Forex, Commodities and CFD
                  has never been easier thanks to the Securewealthai Online Trader.
                  Securewealthai grants you a gateway to all your trading needs with
                  the online and mobile trading applications.
                </p>
              </div>
            </div>
            <div className="web__trader-2">
              <p>
                They are extremely intuitive and functional, enabling you to
                start a trade whenever you want, from anywhere in the world. In
                case you already have a trading account you can start a trade
                immediately.
              </p>
              <p>
                The Securewealthai Online Trader present clients with the option to
                choose from a number of languages, it features the latest
                charts, fast operation of the trades, and best of all, it makes
                everything easy. You have the ability to access to trader from a
                number of different devices such as Apple or Android phone,
                tablet or PC. The platform is easy to use on every device and
                you can handle your investments with just a few clicks.
              </p>
              <p>
                Due to the intuitiveness and easiness of use, the Securewealthai
                Online trader will make sure that you are fully informed about
                all trades that interest you. Stay knowledgeable on the latest
                market news and trends, without having to leave your couch.
              </p>
              <p>
                The financial market can be very volatile and the conditions can
                change in a matter of minutes. Therefore, you need to be able to
                react quickly in such cases. With the Securewealthai Online Trader,
                you act accordingly and access your trades at any moment. The
                advantage is in your hands.
              </p>
              <p>
                With the hastened pace of modern life, you need to be constantly
                on the go, but the platform lets you combine that with your
                trading needs, without having to compromise. It`s accessible
                from any browser, there is no need for you to be stuck to any
                specific place. Be it in your office, or from a friend`s PC,
                logging into your account and trading or just checking something
                is quick and simple.
              </p>
              <p>Using Securewealthai to trade is like a walk in the park.</p>
            </div>

            <div className="web__trader-info ">
              <div className="web__trader-info-heading">
                <h2>It Allows You:</h2>
              </div>
              <div className="web__trader-infos">
                {/* Web trader Info 1 */}
                <div className="web__trader-info-1">
                  <div className="wb-info">
                    <i className="fas fa-circle"></i>
                    <p>Have full control over your profile</p>
                  </div>
                  <div className="wb-info">
                    <i className="fas fa-circle"></i>
                    <p>Perform in-depth market analysis</p>
                  </div>
                  <div className="wb-info">
                    <i className="fas fa-circle"></i>
                    <p>Invest in a wide selection of asset</p>
                  </div>
                  <div className="wb-info">
                    <i className="fas fa-circle"></i>
                    <p>Instant Execution</p>
                  </div>
                  <div className="wb-info">
                    <i className="fas fa-circle"></i>
                    <p>Buy Limit, Buy Stop, Sell Limit, Sell Stop</p>
                  </div>
                  <div className="wb-info">
                    <i className="fas fa-circle"></i>
                    <p>Automated Trading</p>
                  </div>
                  <div className="wb-info">
                    <i className="fas fa-circle"></i>
                    <p>Secure Application</p>
                  </div>
                </div>
                {/* web trader 2 */}
                <div className="web__trader-info-2">
                  <div className="wb-info">
                    <i className="fas fa-circle"></i>
                    <p>Have full control over your profile</p>
                  </div>
                  <div className="wb-info">
                    <i className="fas fa-circle"></i>
                    <p>3rd party bridging</p>
                  </div>
                  <div className="wb-info">
                    <i className="fas fa-circle"></i>
                    <p>Availability of four pending orders</p>
                  </div>
                  <div className="wb-info">
                    <i className="fas fa-circle"></i>
                    <p>Partial closing and many others</p>
                  </div>
                  <div className="wb-info">
                    <i className="fas fa-circle"></i>
                    <p>Advanced charts availability</p>
                  </div>
                  <div className="wb-info">
                    <i className="fas fa-circle"></i>
                    <p>0.01 lot sizes</p>
                  </div>
                </div>
              </div>
              <a href="https://web-trader.online/#/">
                <button className="web__trader-info-btn">
                  Go to web trader
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Trading;
