import React from "react";
import { HashLink as Link } from "react-router-hash-link";
const Accounts = () => {
  return (
    <>
      <section className="accounts" id="types">
        <div className="accounts__wrapper">
          <div className="accounts__heading">
            <h2>Account types</h2>

            <p>
              Securewealthai offers you three different account types from you can
              choose <br />
              from.
              <br />
              Each of the come with their own benefits.
            </p>
          </div>
        </div>
      </section>
      {/* Pricing */}

      <section className="pricing">
        {/* Pricing Card */}
        <div className="pricing__card" style={{ backgroundColor: "#003B7E" }}>
          <div className="pricing__card-heading">
            {/* Heading */}
            <h2>MINI</h2>
            <p>
              Basic Trading for
              <br />
              Beginners
            </p>
            <h3>$500</h3>
          </div>
          {/* Info */}
          <div className="pricing__card-info">
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Online Chat Support</p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Platforms: MT4﹠Mobile</p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Instruments: Forex, CFDs﹠Commodities</p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Free E-book </p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Individual Competitive Spreads﹠Commissions </p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Access to Breaking News﹠ Market Research</p>
            </div>
          </div>
          <a href="https://client.securewealthai.pro/register">
            <button className="pricing__card-btn">Open Account</button>
          </a>
        </div>
        {/* Pricing Card */}
        <div className="pricing__card" style={{ backgroundColor: "#0065A3" }}>
          <div className="pricing__card-heading">
            {/* Heading */}
            <h2>Classic</h2>
            <p>
              For Skilled <br />
              Investors
            </p>
            <h3>$5000</h3>
          </div>
          {/* Info */}
          <div className="pricing__card-info">
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Online Chat Support</p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Platforms: MT4﹠Mobile</p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Instruments: Forex, CFDs﹠Commodities </p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Free E-book </p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Individual Competitive Spreads﹠Commissions </p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Access to Breaking News﹠ Market Research</p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Online Phone Support </p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Real-Time Trading Signals </p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Trading Education Program</p>
            </div>
          </div>
          <a href="https://client.securewealthai.pro/register">
            <button className="pricing__card-btn">Open Account</button>
          </a>
        </div>

        {/* Pricing Card */}
        <div className="pricing__card" style={{ backgroundColor: "#003B7E" }}>
          <div className="pricing__card-heading">
            {/* Heading */}
            <h2>Premium</h2>
            <p className="pricingM">Advanced Trading For</p>
            <p> Experienced Investors</p>
            <h3>$20,000</h3>
          </div>
          {/* Info */}
          <div className="pricing__card-info">
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Online Chat Support</p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Platforms: MT4﹠Mobile</p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Instruments: Forex, CFDs﹠Commodities </p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Free E-book </p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Individual Competitive Spreads﹠Commissions </p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Access to Breaking News﹠ Market Research</p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Online Phone Support </p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Real-Time Trading Signals </p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Trading Education Program</p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Account Executive Team</p>
            </div>
            <div className="info__box">
              <i className="fas fa-check"></i>
              <p>Available 24/7 Trade Mentor</p>
            </div>
          </div>
          <a href="https://client.securewealthai.pro/register">
            <button className="pricing__card-btn">Open Account</button>
          </a>
        </div>
      </section>
      {/* Account Funding */}
      <section className="account__funding" id="funding">
        <div className="account__funding-wrapper">
          {/* Account heading */}
          <div className="account__funding-heading">
            <h2>Account funding</h2>
            <p>There are 3 different ways to fund your Securewealthai account:</p>
          </div>
          {/* Account Content */}
          <div className="account__funding-content">
            <div className="account__funding-content-1">
              <div className="accCard1">
                <h2>1. Credit Card/Debit Card</h2>
                <p>
                  The best way to fund your account is with a credit or debit
                  card. The transfer is immediate and secure.
                </p>
                <p>
                  Minimum transaction amount $250 with a maximum amount $500.
                  Please not your card should be enabled for international
                  transactions.
                </p>
              </div>
              <div className="accCard2">
                <h2>2. Bank Draft</h2>
                <p>
                  You can utilize your banks services and transfer funds using a
                  bank draft. Please note that in some cases it might take up to
                  5 days for your funds to clear.
                </p>
              </div>
              <div className="accCard3">
                <h2>3. Wire Transfer</h2>
                <p>
                  Please use the details provided by your Senior Broker. The
                  minimum amount is $10.000 for bank wires. If you have online
                  banking you can input the details provided online, or visit
                  your bank and fill in the wire transfer form. Please remember
                  to provide your Senior Broker the remittance slip. Depending
                  on your bank your funds might take up to 5 days to clear. As
                  soon as the funds are in your trading account you will receive
                  an email notification.
                </p>
              </div>
              <h4>
                In case you would like to ask anything related to putting money
                on the Live Account, feel free to get in touch with a Securewealthai
                employee
              </h4>
              <Link to="/about#contact">
                <button className="account__funding-btn">Get In Touch</button>
              </Link>
            </div>
            <div className="account__funding-content-2">
              <div className="acc__withdraw">
                <h2>Withdraw Funds</h2>
                <p>In order to submit a withdrawal request you need to:</p>
                <div className="acc-info">
                  <i className="fas fa-circle"></i>
                  <p>
                    Log in to your account in your client area and submit a
                    withdrawal request.
                  </p>
                </div>
                <div className="acc-info">
                  <i className="fas fa-circle"></i>
                  <p>
                    Your request will be addressed and processed promptly.
                    Usually, provided the account is verified the request is
                    process within the next 2 business days. Depending on your
                    bank, your funds might take longer to appear in your bank
                    account.
                  </p>
                </div>
              </div>
              <div className="acc__things">
                <h2>Things you should take into account:</h2>
                <p>In order to submit a withdrawal request you need to: </p>
                <div className="acc-info">
                  <i className="fas fa-circle"></i>
                  <p>
                    The initial amount invested will be returned through the
                    same method deposited. If the requested amount exceeds the
                    initial invested amount, you need to provide us with the
                    bank account details of an account under your name, in order
                    to receive the excess amount
                  </p>
                </div>
                <div className="acc-info">
                  <i className="fas fa-circle"></i>
                  <p>
                    For further inquiries regarding funding please contact us on
                    supportsecurewealthai.pro or through our live chat.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Accounts;
