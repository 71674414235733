import React from "react";
import { HashLink as Link } from "react-router-hash-link";

const Footer = () => {
  return (
    <>
      <section className="footer">
        <div className=" footer__wrapper">
          {/* Footer Links */}
          <div className="footer__links">
            {/* Footer Btn */}
            <div className="footer__btn">
              <a
                href="https://client.securewealthai.pro/"
                className="footer__lg-btn"
              >
                Log in
              </a>
              <a
                href="https://client.securewealthai.pro/register"
                className="footer__oa-btn"
              >
                Open Account
              </a>
            </div>
            <div className="footer__nav">
              <h4 className="footer__nav__heading">Fast Links</h4>
              <div className="footer__nav__links">
                <div className="link1">
                  <Link to="/about#us" onClick={() => window.scrollTo(0, 0)}>
                    About US
                  </Link>
                  <Link to="/about#contact">Contact US</Link>
                  <Link to="/accounts#types">Account Types</Link>
                </div>
                <div className="link2">
                  <Link to="/trading">PLATFORM</Link>
                  <Link to="/terms">Terms and Condition</Link>
                  <Link to="/privacy">Privacy policy</Link>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* Footer Content */}
          <div className="footer__content">
            <h4 className="fc__heading">Risk Warning:</h4>
            <p className="fc1">
              Trading in Forex and CFDs carries a high level of risk and can
              result in the loss of part or all of your investment. Trading in
              Forex and CFDs may not be appropriate for all investors. You
              should be aware of all the risks associated with Forex and CFD
              trading and seek for independent advice. Past performance does not
              constitute a reliable indication of future results. Forecasts for
              the future do not constitute a reliable indicator of future
              performance. Copyright for the pages and for the screens
              displaying the pages, and for the information, material and their
              arrangement, is owned by Securewealthai all rights reserved.
            </p>
            <p className="fc2">
              This information is intended for investors outside the United
              States who are not US/Japanese citizens and residents. This
              website is intended for informational purposes only. This website
              is not directed at any jurisdiction and is not intended for any
              use that would be contrary to local law or regulation. The
              products described on this are not offered and may not be sold in
              the United States/Japan or to US/Japanese citizens and residents.
            </p>
          </div>
        </div>
        {/* Footer Copyright */}
        <div className="copyright">
          <p>© 2024 ALL right reserved Securewealthai</p>
        </div>
      </section>
    </>
  );
};

export default Footer;
