import React from "react";
import Carousel from "react-elastic-carousel";
import { HashLink as Link } from "react-router-hash-link";
import sliderImg1 from "../assets/sliderImg1.png";
import sliderImg2 from "../assets/sliderImg2.png";
import sliderImg3 from "../assets/sliderImg3.png";
import sliderImg4 from "../assets/sliderImg4.png";
import sliderImg5 from "../assets/sliderImg5.png";
const Slider = () => {
  const breakPoints = [{ width: 1, itemsToShow: 1 }];
  return (
    <section className="slider">
      <Carousel breakPoints={breakPoints}>
        <div
          className="slider__single"
          style={{
            backgroundImage: `url(${sliderImg1})`,
          }}
        >
          <div className="slider__single-wrapper">
            <div className="slider__single-content">
              <h2 className="slider__single-content-heading">
                For live assistance <br />
                Securewealthai Provides <br />
                you with 24 hour
                <br /> support
              </h2>
              <button className="slider__single-content-btn">
                <Link to="/about#contact">Learn More</Link>
              </button>
            </div>
          </div>
        </div>
        <div
          className="slider__single"
          style={{
            backgroundImage: `url(${sliderImg2})`,
          }}
        >
          <div className="slider__single-wrapper">
            <div className="slider__single-content">
              <h2 className="slider__single-content-heading">
                Embrace every opportunity
                <br /> to trade - fast and reliable
                <br /> trade executions with
                <br /> transperent pricing
              </h2>
              <button className="slider__single-content-btn">
                <Link to="/about#contact">Learn More</Link>
              </button>
            </div>
          </div>
        </div>
        <div
          className="slider__single"
          style={{
            backgroundImage: `url(${sliderImg3})`,
          }}
        >
          <div className="slider__single-wrapper">
            <div className="slider__single-content">
              <h2 className="slider__single-content-heading">
                Super fast speed in the
                <br /> execution of orders,
                <br /> with milion of orders <br />
                performed so far
              </h2>
              <button className="slider__single-content-btn">
                <Link to="/about#contact">Learn More</Link>
              </button>
            </div>
          </div>
        </div>
        <div
          className="slider__single"
          style={{
            backgroundImage: `url(${sliderImg4})`,
          }}
        >
          <div className="slider__single-wrapper">
            <div className="slider__single-content">
              <h2 className="slider__single-content-heading">
                Futures is a broadly used
                <br /> trading instrument, which <br />
                could be also defined <br />
                thought-out as a forward
                <br /> contract.
              </h2>
              <button className="slider__single-content-btn">
                <Link to="/about#contact">Learn More</Link>
              </button>
            </div>
          </div>
        </div>
      </Carousel>
    </section>
  );
};

export default Slider;
