import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import logo from "../../assets/securewealth-logo.png";
import logodk from "../../assets/securewealth-logo.png";
import "./style.css";
const Navbar = () => {
  const [navOpen, isNavOpen] = useState(false);
  const [dark, setDark] = useState(false);
  const location = useLocation();
  const toggle = () => {
    isNavOpen(!navOpen);
  };
  useEffect(() => {
    const span = document.getElementById("hamburger");
    const btn = document.getElementById("btn");
    if (location.pathname === "/privacy" || location.pathname === "/terms") {
      setDark(true);
      span.classList.add("dark");
      btn.classList.add("darkBtn");
    } else {
      setDark(false);
      span.classList.remove("dark");
      btn.classList.remove("darkBtn");
    }
  });
  return (
    <header className={`${navOpen ? "headerW" : ""}`}>
      <nav className="nav">
        <div className="nav__wrapper">
          <Link to="/" className="nav__logo">
            <img src={navOpen || dark ? logodk : logo} alt="Logo" />
          </Link>
          <div className="nav__flex">
            <a
              href="https://client.securewealthai.pro/"
              className={`${navOpen ? "hide" : "login"}`}
              id="btn"
            >
              Log in
            </a>
            <div
              className={`${
                navOpen ? "nav__hamburger open" : "nav__hamburger"
              }`}
              onClick={toggle}
              id="hamburger"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </nav>
      <div
        className={`${
          navOpen
            ? "nav__links has-fade fade-in"
            : "nav__links has-fade fade-out"
        }`}
      >
        <div className="wrapper">
          <div className="content">
            <ul className="links">
              <li className="navBtns">
                <a
                  href="https://client.securewealthai.pro/register"
                  className="loginBtn"
                >
                  Open Account
                </a>
                <a href="https://client.securewealthai.pro/" className="loginBtn">
                  Login
                </a>
              </li>
              <li>
                <Link to="/" className="desktop-link" onClick={toggle}>
                  About US
                </Link>
                <input type="checkbox" id="show-about" />
                <label htmlFor="show-about">About</label>
                <ul>
                  <li>
                    <Link to="/about#us" onClick={toggle}>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/about#contact" onClick={toggle}>
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/" className="desktop-link" onClick={toggle}>
                  Accounts
                </Link>
                <input type="checkbox" id="show-accounts" />
                <label htmlFor="show-accounts">Accounts</label>
                <ul>
                  <li>
                    <Link to="/accounts#types" onClick={toggle}>
                      Account Types
                    </Link>
                  </li>
                  <li>
                    <Link to="/accounts#funding" onClick={toggle}>
                      Account Funding
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/trading" className="desktop-link" onClick={toggle}>
                  Trading
                </Link>
                <input type="checkbox" id="show-trading" />
                <label htmlFor="show-trading">Trading</label>
                <ul>
                  <li>
                    <Link to="/trading" onClick={toggle}>
                      Web Trader
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/" className="desktop-link" onClick={toggle}>
                  RESOURCES
                </Link>
                <input type="checkbox" id="show-resources" />
                <label htmlFor="show-resources">Resources</label>
                <ul>
                  <li>
                    <Link to="/privacy" onClick={toggle}>
                      Privacy policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms" onClick={toggle}>
                      Terms and Condition
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Navbar;
