import React from "react";
import tradeImg from "../assets/tradeImg.png";
import Slider from "../Components/Slider";
const Home = () => {
  return (
    <>
      {/* Hero Section */}
      <section className="hero">
        <div className="hero__wrapper">
          <div className="hero__heading">
            <h2>
              Innovate power tools <br />
              that aling with your goals
            </h2>
          </div>
          <div className="hero__text">
            <p>
              Trading in Forex, Metals, Indexes, Energies,
              <br /> Stocks, ETFs and Cryptocurrencies
            </p>
          </div>
          <a href="https://client.securewealthai.pro/register" className="hero__btn">
            OPEN ACCOUNT
          </a>
        </div>
        <div className="hero__info">
          <h4>Stocks</h4>
          <h4>Forex</h4>
          <h4>Crypto</h4>
        </div>
      </section>
      <section className="trade__info ">
        <div className="trade__info__wrapper">
          <div className="trade__info-content">
            <div className="trade__info-content-info">
              <h2>
                A TRADE ORIENTED
                <br /> BROKER
              </h2>
              <p>
                Securewealthai is a top online Forex broker with many years of <br />
                experience in the market. Securewealthai being a client-oriented{" "}
                <br />
                company, places the needs of every one of our customers as its
                <br />
                own priority. We are committed to providing all our investors a
                <br />
                wide range of options when trading to help them become better at
                <br />
                trading and also make profits.
                <br />
              </p>
              <p>
                At Securewealthai we ensure all our clients are up to date with the
                <br />
                market trends and also provide them with latest trading
                <br />
                technology and Forex tools by investing continuously in
                <br />
                innovations. These instruments are updated on a regular basis
                <br />
                thereby offering new platforms and services to answer the
                <br />
                various questions and meet the need of every trader, in so
                <br />
                doing, we are keeping our promise and commitment towards a fair
                <br />
                and open trading.
                <br />
              </p>
            </div>
            <div className="trade__info-content-img">
              <img src={tradeImg} alt="tradeImg" />
            </div>
          </div>
          <div className="trade__info-details">
            <p>
              All our trusted clients have a 24/7 access to our various
              comprehensive educational materials. These materials help trader’s
              understand and learn <br /> more about the various online
              operations, trading and
              <br />
              the global market in general. We have a great customer support
              team which is always and readily available to assist with any
              issue you might encounter or challenge you might face in the
              course of trading.{" "}
            </p>
            <p>
              At Securewealthai we place high importance and emphasis on customer
              satisfaction, and we are dedicated to being more than just an
              online broker to our clients. Make your trading experience worth
              it, choose a right broker, and choose Securewealthai!
            </p>
          </div>
        </div>
      </section>
      {/* Trade  Section */}
      <section className="trade">
        <div className=" trade__wrapper">
          <div className="trade__heading">
            <h2>Trade with us</h2>
            <p>
              Securewealthai presents its clients with the optimal platform <br />
              for investing in Forex, Futures and Commodities.
              <br /> We are committed to providing each and every
              <br /> client with the best experience possible and <br />
              always aim to provide the necessary means
              <br /> and resources for successful trading.
            </p>
          </div>
          <div className="trade__content">
            <h2>
              Securewealthai provides
              <br /> you with:
            </h2>
            <div className="trade__content-cards">
              <div className="trade___single-card">
                <img
                  src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/50/003B7E/external-bell-twitter-flatart-icons-outline-flatarticons.png"
                  alt="icon"
                />
                <h4>
                  Timely reports and indicators which let you follow how your
                  trading develops with each signal, along with vital success
                  strategies
                </h4>
              </div>
              <div className="trade___single-card">
                <img
                  src="https://img.icons8.com/ios/50/003B7E/guest-male.png"
                  alt="logo"
                />
                <h4>
                  Various account options catering to each client`s specific
                  circumstances. You can also upgrade your account at a later
                  stage
                </h4>
              </div>
              <div className="trade___single-card">
                <img
                  src="https://img.icons8.com/external-becris-lineal-becris/50/003B7E/external-responsive-job-resume-becris-lineal-becris.png"
                  alt="icon"
                />
                <h4>
                  Top notch trading platforms available on all relevant devices
                  – PC, Mac, Android and Apple phones.
                </h4>
              </div>
              <div className="trade___single-card">
                <img
                  src="https://img.icons8.com/ios/50/003B7E/ingredients-list.png"
                  alt="logo"
                />
                <h4>
                  Algorithmic Trading a computer program which aids you by
                  making trading faster and better overall
                </h4>
              </div>

              <div className="trade___single-card">
                <img
                  src="https://img.icons8.com/dotty/50/003B7E/stocks-growth.png"
                  alt="logo"
                />
                <h4>
                  Means for investing in Forex, futures and indices on a wide
                  number of international marketplaces.
                </h4>
              </div>
              <div className="trade___single-card">
                <img
                  src="https://img.icons8.com/external-wanicon-lineal-wanicon/50/003B7E/external-setting-seo-and-web-wanicon-lineal-wanicon.png"
                  alt="logo"
                />
                <h4>
                  Excellent client care and technical support, which lets you
                  focus on trading
                </h4>
              </div>
              <div className="trade___single-card">
                <img
                  src="https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/50/003B7E/external-support-advertising-kiranshastry-lineal-kiranshastry.png"
                  alt="logo"
                />
                <h4>
                  A personalized Education System massive amounts of trading
                  information at your disposal
                </h4>
              </div>
              <div className="trade___single-card">
                <img
                  src="https://img.icons8.com/external-itim2101-lineal-itim2101/50/003B7E/external-database-big-data-itim2101-lineal-itim2101-5.png"
                  alt="logo"
                />
                <h4>Superior request processing and spreads</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Slider */}
      <Slider />
      {/* About */}
      <section className="about">
        <div className=" about__wrapper">
          <div className="about__heading">
            <h2>
              Why should you choose to <br />
              trade with us?
            </h2>
            <p>
              Your goals are unique, so your investment guidance should be too.
              This is why at
              <br /> Securewealthai we take the time to listen and understand what
              matters most to you before
              <br /> helping you develop and manage your investment strategy.
            </p>
          </div>
          <div className="about__content">
            <div className="about__card">
              <h2>
                INTUITIVE TOOLS AND <br />
                COMPETITIVE PRICING
              </h2>
              <p>
                Whether you’re an active trader or long-term investor our web,
                mobile, and downloadable platforms give you everything you need
                to research stocks, place trades, and manage your portfolio with
                live-streaming access, real-time quotes and specialists with
                years of floor trading experience. As an Securewealthai broker, we do
                not have any conflict of interest regarding our clients’ trades,
                as we only receive a commission on the volume of the trades and
                do not benefit in any additional way by any client losses.
              </p>
            </div>
            <div className="about__card">
              <h2>
                TRANSPARENT AND <br /> TRUSTWORTHY
              </h2>
              <p>
                For a few years now, Securewealthai has been proud to help our
                clients pursue their financial goals while giving them more time
                to focus on what really matters in life. Everything we provide,
                from guidance to powerful trading tools, to retirement resources
                is built around one thing, you. We know it’s important to get
                professional guidance you can trust. That’s why our experienced,
                licensed reps are available by phone, email, online, and in
                person for one-on-one support when you need it most. You can
                always count on us.
              </p>
            </div>
            <div className="about__card">
              <h2>SECURED FUNDS</h2>
              <p>
                Your funds are kept in segregated 3rd party regulated financial
                institutions and we use advanced encryption tools to keep all of
                your transactions and personal data safe and secured. Also our
                flat-rate commission on all online equity trades gives you
                access to our award-winning investing and trading experience.
                Plus, we keep our pricing simple with no share limits or hidden
                fees, no trade requirements to access advanced features, and no
                deposit minimums. At Securewealthai all transactions are simple and
                direct.
              </p>
            </div>
            <div className="about__card">
              <h2>ALGO TRADING</h2>
              <p>
                Algorithmic Trading is a program that offers everyone the
                opportunity to trade with better precision and high success rate
                than he/she would normally get. It makes use of a scenario-based
                approach and artificial intelligence so as to develop the best
                trading approach for the circumstances. Different variables such
                as price, quantity and time period are taken into serious
                consideration as well. The number of traders that use our
                Algorithmic trading keeps increasing by the day, as it makes the
                trading journey easy and well-profiting.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Steps */}
      <section className="steps container">
        <div className="steps__wrapper">
          <h2 className="steps__heading">
            STEPS FOR OPENING A
            <br /> Securewealthai TRADING PROFILE
          </h2>

          <div className="steps__contents">
            {/* Single Steps */}
            <div className="steps__item">
              <h1 className="steps__item-number">1</h1>
              <div className="steps__item-content">
                <h4 className="steps__item-heading">
                  Pick the account
                  <br /> types depending
                  <br /> on your objectives <br />
                  and needs
                </h4>
                <p className="steps__item-text">
                  Three available account types are <br />
                  supplemented with lots of additional <br />
                  features that bring some extra pluses <br />
                  to the investor. You can easily sign up <br />
                  for any of them. And keep the notice <br />
                  that you can change the account type
                  <br /> after putting money on your acc.
                </p>
              </div>
            </div>
            <div className="steps__item">
              <h1 className="steps__item-number">2</h1>
              <div className="steps__item-content">
                <h4 className="steps__item-heading">
                  Fill out the <br />
                  demanded <br />
                  information and <br />
                  confirm your <br />
                  identity
                </h4>
                <p className="steps__item-text">
                  You just need to complete <br />
                  information profile with your
                  <br /> personal data and to provide <br />
                  some identity papers.
                </p>
              </div>
            </div>
            <div className="steps__item">
              <h1 className="steps__item-number">3</h1>
              <div className="steps__item-content">
                <h4 className="steps__item-heading">
                  Depositing money <br />
                  into your account
                </h4>
                <p className="steps__item-text">
                  Once we approve and authorize your
                  <br /> application, you will be able to make
                  <br /> your payments and withdrawals swiftly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
